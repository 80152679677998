import { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import { Modal } from "antd";
import { useInitData, retrieveLaunchParams } from '@telegram-apps/sdk-react';
import { initUtils } from '@telegram-apps/sdk';
// import { getTonBalance, truncateString, getAvatar, getTxnType } from '../../web3/ultils';


import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/react';

import toast from "react-hot-toast";
import tonCoin from "../../assets/img/ton.png";
import logo from "../../assets/img/logo.png";
import { getUser, addPoint, getTonTxnByUser } from '../../web3/app';



const projectId = `4c0c30088e0b47be403161b2804dae2f`;

const mainnet = {
  chainId: 1,
  name: 'Ethereum',
  currency: 'ETH',
  explorerUrl: 'https://etherscan.io',
  rpcUrl: 'https://cloudflare-eth.com'
}
const metadata = {
  name: 'SpriteGame',
  description: '',
  url: 'https://sprite.games',
  icons: ['https://miniapp.sprite.games/logo.png']
}

const ethersConfig = defaultConfig({
  metadata,
  /*Optional*/
  // enableEIP6963: true, // true by default
  // enableInjected: true, // true by default
  // enableCoinbase: true, // true by default
  // rpcUrl: '...', // used for the Coinbase SDK
  // defaultChainId: 1 // used for the Coinbase SDK
})

createWeb3Modal({
  ethersConfig,
  chains: [mainnet],
  projectId,
  enableAnalytics: false // Optional - defaults to your Cloud configuration
})

export default function Wallet() {
    
    const navigate = useNavigate();
    const [userData, setUserData] = useState<any>();
    const [tonBalance, setTonBalance] = useState(0);

    const [isRefModalOpen, setRefModalOpen] = useState(false);


    // const { initDataRaw } = retrieveLaunchParams();
    // const initData = useInitData();
    // const teleUser = initData.user;
    // let utils = initUtils();

    let utils;
    let initDataRaw;
    let teleUser = {
        id: `740772070`,
        username: `judasle7`,
        firstName: `Judas`,
        lastName: `L`
    };

    let walletAddress;

    const handleCancelRefModal = () => {
        setRefModalOpen(false);
    }

    
    useEffect(() => {
        fetchUser();
	}, []);

    const handleDisconnect = async () => {
        // await tonConnect.disconnect();
    }

    useEffect(() => {
        fetchTonBalance();
        const interval = setInterval(() => {
            fetchTonBalance();
            fetchUser();
        }, 15000);
        return () => clearInterval(interval);
    }, [walletAddress]);


    const fetchUser = async () => {
        const user = await getUser(teleUser.id);
        if(user){
            const txns = await getTonTxnByUser(user.id);
            setUserData({...user, txns});
            // check wallet
            if(walletAddress && user.is_wallet_connected == 0){
                const updated = await addPoint({tg_id: teleUser.id, amount: 1000, wallet: walletAddress, point_type: 'wallet'}, initDataRaw); // connect wallet
            }
        }else{
            navigate("/", { replace: true });
        }
    };

    const fetchTonBalance = async () => {
        // setTonBalance(await getTonBalance(walletAddress))
    }

    return(
        <>
            <div className="relative wallet">
                <div className="frame-2">
                    <div className="frame-2-inner">
                        <div className="head">{userData?.tele_username}</div>
                        <div className="badge-tab flex items-center justify-center gap-1">
                            <NavLink to="/wallet" className="badge-tab-item current"
                            >
                                WALLET
                            </NavLink>
                            <NavLink to="/wallet/history" className="badge-tab-item"
                            >
                                TXNS
                            </NavLink>
                        </div>

                        <div className="frame-2-content"> 
                            <div className="mb-2">
                                <div className="amount-bg point">
                                    {userData?.point}
                                </div>
                                <div className="amount-bg">
                                    {userData?.ton}
                                </div>
                            </div>

                            {
                                walletAddress ? 
                                <>
                                    <div className="leaderboards p-2 text-white mb-5 flex items-end justify-between gap-2">
                                        <div>
                                            <h2 className="text-[12px] mb-1">Connected Wallet</h2>
                                            {/* <p className="text-[16px] ">{ truncateString(walletAddress.toString(), 4, 6) }</p> */}
                                            <div className="flex items-center gap-2 text-[16px] mt-3">
                                                <div>{tonBalance.toLocaleString('en-US')}</div>
                                                <img className="w-[20px]" src={tonCoin} />
                                            </div>
                                        </div>
                                        
                                        <button
                                            className="px-2 text-xs py-1 rounded-md text-white bg-[#562F00] flex justify-center items-center gap-2"
                                            onClick={() => {
                                                handleDisconnect()
                                            }}
                                        >
                                            <span>Disconnect</span>
                                        </button>
                                    </div>
                                </>
                                :
                                <div className="leaderboards p-2 text-white mb-5 flex items-end justify-center gap-2">
                                    <w3m-button />
                                </div>
                            }

                            <div className={`text-center mt-3 flex justify-center gap-2 ${walletAddress ? ``:`opacity-50 pointer-events-none`} `}>
                                <NavLink
                                    to="/wallet/deposit"
                                    className="btn is-small secondary mb-2"
                                >
                                    <span>DEPOSIT</span>
                                </NavLink>
                                <NavLink
                                    to="/wallet/deposit"
                                    className="btn is-small primary"
                                >
                                    <span>WITHDRAW</span>
                                </NavLink>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </>
    );
}