import React from "react";


import { SDKProvider } from '@telegram-apps/sdk-react';

import { Toaster } from "react-hot-toast";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import "./App.css";

import TemplateLayout from "./components/Layout";
import Home from "./page/Home";
// import Badge from "./page/Badge";
// import Items from "./page/Items";
// import Market from "./page/Market";
// import MarketItems from "./page/MarketItems";
// import SpriteDetail from "./page/SpriteDetail";
// import Maps from "./page/Maps";
// import Leaderboard from "./page/Leaderboard";
import Wallet from "./page/Wallet";
// import WalletHistory from "./page/WalletHistory";
import WalletDeposit from "./page/WalletDeposit";
// import NewQuest from "./page/NewQuest";

function App() {
  return (
    <SDKProvider acceptCustomStyles>
      <Router>
        <TemplateLayout>
          <Routes>
            <Route path="/" element={<Home />} />
            {/* <Route path="/sprite" element={<Badge />} />
            <Route path="/sprite/items" element={<Items />} />
            <Route path="/market" element={<Market />} />
            <Route path="/market/items" element={<MarketItems />} />
            <Route path="/sprite/:id" element={<SpriteDetail />} />
            <Route path="/maps" element={<Maps />} />
            <Route path="/leaderboard" element={<Leaderboard />} /> */}
            <Route path="/wallet" element={<Wallet />} />
            {/* <Route path="/wallet/history" element={<WalletHistory />} /> */}
            <Route path="/wallet/deposit" element={<WalletDeposit />} />
            {/* <Route path="/newquest" element={<NewQuest />} /> */}
          </Routes>
        </TemplateLayout>
      </Router>
      <Toaster
        position="top-center"
        toastOptions={{
          duration: 2500,
        }}
      />
    </SDKProvider>
  );
}

export default App;
